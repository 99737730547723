'use client';

import AuthGuard from '@/src/components/AuthGuard';
import { ApiProvider } from '@/src/providers/Api';
import type { ReactNode } from 'react';
import { AuthProvider } from '@descope/nextjs-sdk';
import useEnvironment from '@/src/providers/Environment';
import { HomeMeProvider } from '@/src/providers/Me';

export default function NoTenantLayout({ children }: { children: ReactNode }) {
  const { natoma, descope } = useEnvironment();
  const descopeProjectId = descope.projectId || '';

  return (
    <AuthProvider
      projectId={descopeProjectId}
      sessionTokenViaCookie
      baseUrl={`https://auth.${natoma.rootDomain}`}
    >
      <ApiProvider>
        <HomeMeProvider>
          <AuthGuard layout="home">{children}</AuthGuard>
        </HomeMeProvider>
      </ApiProvider>
    </AuthProvider>
  );
}
